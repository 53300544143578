import React from 'react'

// markup
const ScenesDistanceFromParvis = ({ className }) => {
  return (
    <div className='ul-container'>
        <div className={`${className}`}>
            <h2 className='text-center'>Distance entre Le Parvis (1 place de l'église) et les scènes</h2>

            <ul className="mt-2"><li><a href='/scenes/place-desjardins' className='!no-underline hover:!underline !font-bold'>Place Desjardins : 50m</a></li></ul>
            <p>Adresse : 23 Ambroise-Fafard</p>
            <p>Moyen de transport à privilégier : marche</p>

            <ul className="mt-2"><li><a href='/scenes/scene-loto-quebec' className='!no-underline hover:!underline !font-bold'>Scène Loto-Québec (Parc de la Virevolte) : 500m</a></li></ul>
            <p>Adresse : 54 Saint-Joseph (entrée du parc)</p>
            <p>Moyen de transport à privilégier : marche</p>

            <ul className="mt-2"><li><a href='/scenes/quai-bell' className='!no-underline hover:!underline !font-bold'>Quai Bell : 2,2 km</a></li></ul>
            <p>Adresse : 210 rue Ste-Anne</p>
            <p>Moyen de transport à privilégier : marche, via le chemin "En route vers le Quai Bell" et vélo, via Le chemin des Soeurs. Une station de Baie-Cycle sera dans ce secteur.</p>

            <ul className="mt-2"><li><a href='/scenes/scene-microbrasserie-charlevoix' className='!no-underline hover:!underline !font-bold'>Scène MicroBrasserie Charlevoix : 250m</a></li></ul>
            <p>Adresse : 2 rue Racine</p>
            <p>Moyen de transport à privilégier : marche</p>

            <ul className="mt-2"><li><a href='/scenes/scene-siriusxm' className='!no-underline hover:!underline !font-bold'>Scène SiriusXM : 25m</a></li></ul>
            <p>Adresse : 1 place de l'église</p>
            <p>Moyen de transport à privilégier : marche</p>

            <ul className="mt-2"><li><a href='/scenes/garage-du-cure' className='!no-underline hover:!underline !font-bold'>Garage du Curé Exclaim! : 25m</a></li></ul>
            <p>Adresse : 1 Pl. de l'Église</p>
            <p>Moyen de transport à privilégier : marche</p>

            <ul className="mt-2"><li><a href='/scenes/le-pit-a-sable' className='!no-underline hover:!underline !font-bold'>Le Pit à sable Hydro-Québec : 3km</a></li></ul>
            <p>Adresse : 160-208 ch. de l'Équerre</p>
            <p>Moyen de transport à privilégier : navette, les autres moyens de transport sont fortement déconseillés</p>

            <ul className="mt-2"><li><a href='/scenes/cour-a-joanne' className='!no-underline hover:!underline !font-bold'>Cour à Joanne : 300 m</a></li></ul>
            <p>Adresse : 49 rue Racine</p>
            <p>Moyen de transport à privilégier : marche</p>

            <ul className="mt-2"><li><a href='/scenes/scene-hydro-quebec' className='!no-underline hover:!underline !font-bold'>Scène Hydro-Québec : 50m</a></li></ul>
            <p>Adresse : 7 rue Forget</p>
            <p>Moyen de transport à privilégier : marche</p>

        </div>
      </div>
  )
}

export default ScenesDistanceFromParvis
