import React from 'react'
import FestifHelmet from '../../components/Helmet/FestifHelmet'
import Wolflayout from '../../components/wolflayout'
import InfosHeader from '../../components/Infos/InfosHeader'
import InfosNavigation from '../../components/Infos/InfosNavigation'
import ScenesDistanceFromParvis from '../../components/Infos/ScenesDistanceFromParvis'
import BigButton from '../../components/Ui/BigButton'

const Transports = () => {
  const metaTitle = 'Transport | Le Festif! de Baie-Saint-Paul'
  const metaDescription = 'Groupes de covoiturage, forfaits avec le Train de Charlevoix, location de vélos, navette vers le Quai Bell : le Festif! est votre allié en transport!'
  const metaKeywords = 'festival, musique, événement, spectacles, québec, charlevoix, transport, se rendre, covoiturage, train'

  return (
    <Wolflayout mobileTitle={'Infos'}>
      <FestifHelmet title={metaTitle} description={metaDescription} keywords={metaKeywords} />
      <InfosNavigation />
      <InfosHeader
        title="Transport"
      />
      <div className="container content pb-12">
        <h2>En direction de Baie-Saint-Paul</h2>
        <p>Dans l’objectif de réduire l’empreinte carbone de l’événement, Le Festif! encourage ses festivalier·ères à opter pour le covoiturage. Rejoins le groupe Facebook pour trouver ton lif : <a href="https://www.facebook.com/groups/267497745542406/">Un lift au Festif!</a>.</p>
        <br/>
        <p>Grâce à Desjardins et à Mobilité Charlevoix, tu pourras embarquer dans la navette gratuite de La Malbaie jusqu’à Baie-Saint-Paul et de Baie-Saint-Paul jusqu’à La Malbaie. Attention, tu dois obligatoirement réserver ta place.</p>

        <h2>À Baie-Saint-Paul</h2>
        <p>Une fois que tu seras à Baie-Saint-Paul, Le Festif! t’encourage à limiter autant que possible l’utilisation de ta voiture, tant par souci écologique que pour éviter de générer d’importants bouchons de circulation dans les rues du village. Ceci étant dit, les sites de spectacles étant tous très près les uns des autres, la majorité des déplacements peuvent aisément être effectués à pied. Pour les rares trajets nécessitant une marche de plus d’une dizaine de minutes, deux options sont offertes :</p>

        <h3>Le vélo</h3>
        <p>Grâce aux copains et aux copines de Baie-Cycle, tu pourras louer une bicyclette pour te déplacer librement dans les rues de Baie-Saint-Paul. Baie-Cycle aura quatre stations à ta disposition.</p>

        <h3>Navettes gratuites vers les sites</h3>

        <a href='/scenes/le-pit-a-sable' className='!no-underline hover:!underline'><h4>Pit à sable Hydro-Québec</h4></a>
        <p>Les navettes sont à privilégier pour se rendre au Pit à Sable Hydro-Québec.</p>
        <br/>
        <p>L’embarquement dans les navettes se fera au 22 Chem. de l’Hôpital (stationnement de l’ancien Hôpital de Baie-Saint-Paul). L’accès à cette rue (aussi appelée Allée des Petites Franciscaines de Marie) se fait via la rue Racine ou R. Alfred-Morin, derrière l’école Sir-Rodolphe Forget. L’endroit se trouve facilement via Google map. Le stationnement pour la navette se trouve à proximité au P2 / P3 / P5.</p>
        <br/>
        <p>Tu dois présenter ton billet électronique ou ta Passe La Totale afin de pouvoir entrer dans les navettes et sur le site.</p>
        <br/>
        <a href='/scenes/quai-bell' className='!no-underline hover:!underline'><h4>Quai Bell</h4></a>
        <p>Les navettes font des allers et retours en continu selon les heures de spectacle en direction du Quai Bell. Rendez-vous devant le Arts & Mer Gens, au 51 rue Sainte-Anne pour l’embarquement.</p>
        <br/>
        <p>Des allers-retours auront lieu entre 10h30 et 11h30.</p>
        <br/>
        <p>Au retour, la navette fera des allers-retours à partir de la fin du spectacle jusqu'à 15h.</p>

        <p><BigButton href="/infos/carte#info_content" text="Voir les stationnements sur la carte" target='_self'/></p>

        <ScenesDistanceFromParvis />

      </div>

    </Wolflayout>
  )
}

export default Transports
